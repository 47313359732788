import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'compubility-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  pages = [
    { route: '/', title: 'Overview'},
    { route: '/privacy', title: 'Privacy Policy'},
    { route: '/terms', title: 'Terms of Service'},
    // { route: '/faqs', title: 'FAQs'},
    { route: '/contact', title: 'Contact'}
  ];
  constructor(public router: Router) { }

  ngOnInit() {
  }

}
