<div fxLayout="column" class="header">
  <div class="header-top" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <img src="https://storage.googleapis.com/compubility_public/compubility_logo.png" alt="Logo" height="40" />
    <div class="header-top__title">
      <span class="blue">Comp</span><span class="purple bolden">ubility</span> | Policies
    </div>
  </div>
  <div class="header-navigation" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" >
    <div class="header-navigation__option"
         [ngClass]="{'header-navigation__option--selected': router.url === page.route}"
         *ngFor="let page of pages">
      <a [routerLink]="page.route">{{ page.title }}</a>
    </div>
  </div>
</div>
