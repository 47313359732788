import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OverviewComponent } from '../components/overview/overview.component';
import { PrivacyComponent } from '../components/privacy/privacy.component';
import { TermComponent } from '../components/term/term.component';
import { FaqsComponent } from '../components/faqs/faqs.component';
import { ContactComponent } from '../components/contact/contact.component';

const appRoutes: Routes = [
  { path: '', component: OverviewComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermComponent },
  // { path: 'faqs', component: FaqsComponent },
  { path: 'contact', component: ContactComponent },
  { path: '', pathMatch: 'full', redirectTo: '' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
